// ** React Imports
import {Suspense} from 'react'
import {Navigate} from 'react-router-dom'

// ** Utils
//import { getUserData, getHomeRouteForLoggedInUser } from "@utils"

import {useAtom} from 'jotai' //, useSetAtom, useAtomValue
import {isLoggedAtom} from '@src/utility/atoms'
//import {DefaultRoute} from '@src/router'

const PublicRoute = ({children, route}) => {
  const [isLogged] = useAtom(isLoggedAtom)

  if (route) {
    //const user = getUserData()
    //const restrictedRoute = route.meta && route.meta.restricted
    //if (user && restrictedRoute) { getHomeRouteForLoggedInUser(user.role)
    // if (isLogged) {
    //   return <Navigate to={DefaultRoute} replace />
    // } else {
    //   //return <Navigate to={'/login'} />
    // }
    //}
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PublicRoute
