import {atom} from 'jotai'
import {atomWithStorage, atomWithReset} from 'jotai/utils'
import type {UserEntity} from '@src/data/UserEntity'
import MqttConnectionEntity from '@src/data/MqttConnectionEntity'
import {
  NextVersionEntity,
  SimSerialEntity,
  VersionEntity
} from '@src/data/VersionEntity'
import MemoryEntity from '@src/data/memoryEntity'
import {AreaEntity} from '@src/data/areaEntity'
import {ContactEntity} from '@src/data/ContactEntity'
import {OutputEntity} from '@src/data/OutputEntity'
import {StatusDetailEntity} from '@src/data/StatusDetail'
import DeviceEntity from '@src/data/deviceEntity'
import RtspEntity from '@src/data/rtspEntity'

const versionMsg: VersionEntity = {
  type: '',
  alarmtype: '',
  idgesco: '',
  name: '',
  so: '',
  sw: '',
  mace: '',
  macw: '',
  versionSam: 0,
  versionEsp: 0,
  isIT1v2: false,
  islockedb: true
}

const loggedUser: UserEntity = {
  name: '',
  isconfig: false,
  isinstaller: false,
  enaserv: false,
  modinst: false,
  idx: -1
}

export const statusGetZero: any = {
  f: '',
  a: '',
  status: {
    infolst: ['VARBOX VB1-3453566', 'Connesso al cloud'],
    numcameras: 2,
    idgesco: 'IT7-1840684407',
    idgescovb: 'VB1-78939837',
    varboxname: 'Test',
    numinputs: 21,
    interfaces: [
      {
        name: 'Ethernet',
        mac: 'ff:gg:99:44:55:66',
        ip: '237.84.2.178'
      },
      {
        name: 'Wifi',
        mac: '22:33:44:55:66',
        ip: '237.84.2.178'
      }
    ]
  }
}

const statusDetailZero: StatusDetailEntity[] = [
  {t: '', k: '', icon: '', icontype: '', iconColor: ''}
]

const nextverMsg: NextVersionEntity = {
  version: '',
  vernum: 0,
  md5: ''
}

export const versionAtom = atomWithReset<VersionEntity>({
  ...versionMsg
})

export const nextVersionAtom = atom<NextVersionEntity>({
  ...nextverMsg
})

export const nextVersionTestAtom = atom<NextVersionEntity>({
  ...nextverMsg
})

export const mqttConnAtom = atomWithReset<MqttConnectionEntity | null>(null)
export const isMqttAtom = atom<boolean>(get => get(mqttConnAtom) != null)

export const errorMsgAtom = atomWithReset<string>('')

export const loggedUserAtom = atomWithReset<UserEntity>(loggedUser)
export const isLoggedAtom = atom<boolean>(get => get(loggedUserAtom)?.idx != -1)

export const darkModeAtom = atomWithStorage('skin', 'light') //dark light

export const isMqttConnectedAtom = atom<boolean>(false)

export const isLoadingAtom = atom<boolean>(false)
export const isLoadingObjAtom = atom<boolean>(false)
export const isRestartingAtom = atom<boolean>(false)
export const isSavingAtom = atom<boolean>(false)
export const isEditingAtom = atom<boolean>(false)
export const isMaintenanceBlockAtom = atom<boolean>(false)

export const curFunctNameAtom = atom<string>('')
export const curActionNameAtom = atom<string>('')

export const genericLstAtom = atomWithReset<Array<any>>([])
export const genericObjAtom = atomWithReset<any>({})
export const configZeroObjAtom = atomWithReset<any>({})
export const genericObjUnmodifiedAtom = atomWithReset<any>({})

export const inputLstAtom = atomWithReset<Array<any>>([])

export const memoryLstAtom = atomWithReset<MemoryEntity[] | null>(null)

export const devicesLstAtom = atomWithReset<DeviceEntity[] | null>(null)

export const rtspsLstAtom = atomWithReset<RtspEntity[] | null>(null)

export const areasLstAtom = atom<AreaEntity[]>([])
export const usersLstAtom = atom<UserEntity[]>([])
export const contactsLstAtom = atom<ContactEntity[]>([])
export const outputsLstAtom = atom<OutputEntity[]>([])

export const simIMEISerialAtom = atom<SimSerialEntity>({ccid: '', imei: ''})

export const statusGetAtom = atom(statusGetZero)
export const statusDetailAtom = atom(statusDetailZero)

export const imgAtom = atomWithReset<string>('')
