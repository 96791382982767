import {Card, CardBody, CardText, Input, Button, Col, Row} from 'reactstrap'
import {useEffect, useState} from 'react'
import {useAtom} from 'jotai'
import {
  devicesLstAtom,
  genericLstAtom,
  genericObjAtom,
  imgAtom,
  inputLstAtom,
  versionAtom
} from '@src/utility/atoms'

const Debug = (props: any) => {
  const {SendCmd} = props

  const [genList] = useAtom(genericLstAtom)
  const [devicesList] = useAtom(devicesLstAtom)
  const [genObj] = useAtom(genericObjAtom)
  const [inputLst] = useAtom(inputLstAtom)
  const [versionMsg] = useAtom(versionAtom)
  const [imageScreen] = useAtom(imgAtom)

  const [resultMqtt, setResultMqtt] = useState<string>('')

  const [message, setMsg] = useState<string>('')
  const [isWait, setIsWait] = useState<boolean>(false)
  const [messageMqtt2, setMsgMqtt2] = useState<string>('')

  const listCameras =
    '{"Hdr":{"function":22,"action":2,"code":"112233"},"Areas":{"lstAreas":[{"id":0,"name":" "}]}}'

  const saveCamera =
    '{"Hdr":{"function":22,"action":1,"code":"112233"},"Areas":{"lstAreas":[{"id":0,"name":"FRiG123","ser1":true,"ser2":true}]}}'

  const getConfig = '{"f":"config","a":"get"}'

  const user_login =
    '{"Hdr":{"function":13,"action":0,"code":"112233"},"Users":{"lstUsers":[{"code":"112233"}]}}'

  const list_input = '{"f":"input","a":"list"}'

  const list_ip = '{"f":"ip","a":"list"}'
  const list_onvif = '{"f":"onvif","a":"list"}'

  const ver_get = '{"f":"version","a":"get"}'

  const userCheck = () => {
    try {
      setMsg(user_login)
    } catch (ex) {
      console.log(ex)
    }
  }

  const sendMsg = async () => {
    try {
      setIsWait(true)
      const msgPlainObj = JSON.parse(message)
      SendCmd(msgPlainObj)
    } catch (ex) {
      console.log(ex)
    }
  }

  const sendMsgNoToken = async () => {
    try {
      setIsWait(true)
      const msgPlainObj = JSON.parse(message)
      SendCmd(msgPlainObj, true)
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    if (isWait) {
      setResultMqtt(
        `${resultMqtt} \n\n ${JSON.stringify(genList)} ${JSON.stringify(
          genObj
        )}`
      )
      setIsWait(false)
    }
  }, [genList, genObj])

  return (
    <div>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2
                className="content-header-title float-left mb-0"
                style={{borderRight: 0}}>
                Debug
              </h2>
            </div>
          </div>
        </div>
      </div>

      <Row>
        <Col sm="6">
          <Card>
            <CardText>
              listCameras: {JSON.stringify(JSON.parse(listCameras))}
            </CardText>
            <CardText>
              SaveCamera: {JSON.stringify(JSON.parse(saveCamera))}
            </CardText>
            <CardText>
              getConfig: {JSON.stringify(JSON.parse(getConfig))}
            </CardText>
            <CardText>
              list_input: {JSON.stringify(JSON.parse(list_input))}
            </CardText>
            <CardText>list_ip: {JSON.stringify(JSON.parse(list_ip))}</CardText>
            <CardText>
              list_onvif: {JSON.stringify(JSON.parse(list_onvif))}
            </CardText>
            <CardText>ver_get: {JSON.stringify(JSON.parse(ver_get))}</CardText>
          </Card>
        </Col>
        <Col sm="6">
          <Card>
            {/* <Button color="primary" type="submit" onClick={loadVersion}>
          versione fw
        </Button>
        <Button color="primary" type="submit" onClick={loadFw}>
          aggiorna fw
        </Button> */}
            {/* <Button color="primary" type="submit" onClick={userCheck}>
              user check login
            </Button> */}
            {/* <button type="button" className="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> */}
            <CardBody>
              <Input
                type="textarea"
                name="text"
                id="exampleText"
                rows="3"
                placeholder="Message"
                value={message}
                onChange={(e: any) => {
                  console.log(e.target.value)
                  setMsg(e.target.value)
                }}
              />
              <br></br>
              <Input
                type="textarea"
                name="text"
                id="resultMqtt"
                rows="9"
                placeholder="Message"
                defaultValue={resultMqtt}
              />
            </CardBody>
            <Button color="primary" type="submit" onClick={sendMsg}>
              Send msg
            </Button>
            <Button color="primary" type="submit" onClick={sendMsgNoToken}>
              Send no token
            </Button>
            {/* <Button color="primary" type="submit" onClick={spamMsg}>
              SPAM!!!
            </Button> */}
            <CardText>genList: {JSON.stringify(genList)}</CardText>
            <CardText>devicesList: {JSON.stringify(devicesList)}</CardText>
            <CardText>genObj: {JSON.stringify(genObj)}</CardText>
            <CardText>inputLst: {JSON.stringify(inputLst)}</CardText>
            <CardText>versionMsg: {JSON.stringify(versionMsg)}</CardText>
            {imageScreen != '' && <img src={imageScreen}></img>}

            {/* <Button color="primary" type="submit" onClick={testBits}>
              test bits
            </Button> */}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Debug
