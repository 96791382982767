import Constant from '@src/utility/Constants'
import {
  Home,
  BookOpen,
  Power,
  Circle,
  LogIn,
  Book,
  PhoneCall,
  Server,
  Tool,
  Shuffle,
  Users,
  Wifi
} from 'react-feather'
import {TbDeviceCctv} from 'react-icons/tb'
import {BiCctv} from 'react-icons/bi'
import {CgDebug} from 'react-icons/cg'
import {VscCircuitBoard} from 'react-icons/vsc'

export default [
  {
    id: 'home',
    title: 'Home',
    icon: <Home size={20} />,
    navLink: '/home'
  },
  {
    id: 'memory',
    title: 'Memoria eventi',
    icon: <BookOpen size={20} />,
    navLink: '/memory'
  },
  {
    id: 'users',
    title: 'Utenti',
    icon: <Users size={20} />,
    navLink: '/users'
  },

  {
    id: 'tools',
    title: 'Manutenzione',
    icon: <Tool size={20} />,
    navLink: '/maintenance',
    children: [
      // {
      //   id: 'tools-blocco',
      //   title: 'Blocco',
      //   icon: <Circle size={20} />,
      //   navLink: `/maintenance/${Constant.MAINTENANCE_BLOCK}`
      // },
      {
        id: 'tools-reboot',
        title: 'Riavvio',
        icon: <Circle size={20} />,
        navLink: `/maintenance/${Constant.MAINTENANCE_REBOOT}`
      },
      {
        id: 'tools-update',
        title: 'Aggiornamenti',
        icon: <Circle size={20} />,
        navLink: `/maintenance/${Constant.MAINTENANCE_UPDATE}`
      },
      {
        id: 'tools-config',
        title: 'Configurazione',
        icon: <Circle size={20} />,
        navLink: `/maintenance/${Constant.MAINTENANCE_CONFIG}`
      }
    ]
  },

  //TODO:2 show readonly
  // {
  //   id: 'shield',
  //   title: 'Servizi',
  //   icon: <Shield size={20} />,
  //   navLink: '/services' //areas
  // },
  // {
  //   id: 'briefcase',
  //   title: 'Periferiche',
  //   icon: <Briefcase size={20} />,
  //   navLink: '/units'
  // },
  // {
  //   id: 'volume',
  //   title: 'Sirena a bordo',
  //   icon: <Volume2 size={20} />,
  //   navLink: '/horn'
  // },

  // // {
  // //   id: 'include_exclude',
  // //   title: 'Escludi/Includi Ingresso',
  // //   icon: <Shuffle size={20} />,
  // //   navLink: '/second-page'
  // // },
  // {
  //   id: 'outputs',
  //   title: 'Uscite',
  //   icon: <LogOut size={20} />,
  //   navLink: '/outputs'
  // },
  {
    id: Constant.F_WIFI,
    title: 'Rete WiFi',
    icon: <Wifi size={20} />,
    navLink: `/${Constant.F_WIFI}`
  },
  //TODO:2 show
  // {
  //   id: 'signal',
  //   title: 'Rete Ethernet',
  //   icon: <BsEthernet size={20} />,
  //   navLink: '/eth'
  // },
  {
    id: 'cameras',
    title: 'Telecamere',
    icon: <BiCctv size={20} />,
    navLink: '/cameras'
  },
  {
    id: 'alarm',
    title: 'Impianto',
    icon: <VscCircuitBoard size={20} />,
    navLink: Constant.PATH_ALARM_SYSTEM
  },
  {
    id: 'inputs',
    title: 'Ingressi (impianto)',
    icon: <LogIn size={20} />,
    navLink: '/inputs'
  },
  // {
  //   id: 'inputs_cam',
  //   title: 'Abbinamento',
  //   icon: <Shuffle size={20} />,
  //   navLink: '/input_cameras'
  // },
  // {
  //   id: 'temp',
  //   title: 'Temporizzazioni',
  //   icon: <Sunset size={20} />,
  //   navLink: '/timings'
  // },
  // {
  //   id: 'rubric',
  //   title: 'Rubrica',
  //   icon: <Book size={20} />,
  //   navLink: '/contacts'
  // },
  // {
  //   id: 'activity',
  //   title: 'Sequenze',
  //   icon: <PhoneCall size={20} />,
  //   navLink: Constant.PATH_SEQUENCES
  // },
  // {
  //   id: 'message',
  //   title: 'Messaggio comune',
  //   icon: <MessageCircle size={20} />,
  //   navLink: '/messages'
  // },

  // {
  //   id: 'debug',
  //   title: 'Debug',
  //   icon: <CgDebug size={20} />,
  //   navLink: '/debug'
  // },
  {
    id: 'logout',
    title: 'Esci',
    icon: <Power size={20} />,
    navLink: '/logout'
  }
]
