// ** React Imports
import {Navigate} from 'react-router-dom'
import {useContext, Suspense} from 'react'

// ** Context Imports
// import { AbilityContext } from "@src/utility/context/Can"

import {useAtom} from 'jotai' //, useSetAtom, useAtomValue
import {isLoggedAtom} from '@src/utility/atoms'

const PrivateRoute = ({children, route}) => {
  // ** Hooks & Vars
  // const ability = useContext(AbilityContext)
  // const user = JSON.parse(localStorage.getItem("userData"))

  const [isLogged] = useAtom(isLoggedAtom)

  if (route) {
    let action = null
    let resource = null
    let restrictedRoute = false

    if (route.meta) {
      action = route.meta.action
      resource = route.meta.resource
      restrictedRoute = route.meta.restricted
    }
    if (!isLogged) {
      return <Navigate to="/login" replace />
    }
    // if (isLogged) {
    //   return <Navigate to="/home" />
    // }
    // if (user && restrictedRoute) {
    //   return <Navigate to="/" />
    // }
    // if (user && restrictedRoute && user.role === "client") {
    //   return <Navigate to="/access-control" />
    // }
    // if (user && !ability.can(action || "read", resource)) {
    //   return <Navigate to="/misc/not-authorized" replace />
    // }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
