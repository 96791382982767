///Utility functions ...
import Constant from '@src/utility/Constants'
import toast from 'react-hot-toast'

class Utility {
  static async sleep(msec: number) {
    return new Promise((resolve: any) => setTimeout(resolve, msec))
  }

  static GenerateRandomString(length: number) {
    let result: string = ''
    const characters: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789|!£$%()=?^<>{}[]@#§'
    const charactersLength: number = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  static replaceOperatorName(operator: string): string {
    operator = operator.replace('WIND WIND', 'WIND')
    operator = operator.replace('WINDTRE WIND TRE', 'WINDTRE')
    operator = operator.replace('WINDTRE WINDTRE', 'WINDTRE')
    operator = operator.replace('ho. ho.', 'ho.')
    operator = operator.replace('FASTWEB FASTWEB', 'FASTWEB')
    operator = operator.replace('CoopVoce CoopVoce', 'CoopVoce')

    return operator
  }

  // static Convert_TripleIndex_ToIdxConnectID(
  //   idx: number,
  //   ty: number,
  //   u: number,
  //   functionn: string
  // ) {
  //   if (functionn == Constant.F_INPUT_SENSOR) {
  //     //Ingressi Centrale
  //     //{"name":"Ing_0_0_1","ex":0,"en":1,"ty":0,"u":0,"idx":1,"state":2}
  //     if (ty == 0 && u == 0) {
  //       return idx
  //     }

  //     //Ingressi Seriale
  //     //{"name":"Ing_1_0_1","ex":0,"en":0,"ty":1,"u":0,"idx":1,"state":0}
  //     if (ty == 1) {
  //       return idx + 8
  //     }

  //     //Ingressi Radio
  //     //{"name":"Sensore_1_1","ex":0,"en":0,"ty":2,"u":0,"idx":1,"state":1}
  //     if (ty == 2) {
  //       return u * 3 + idx + 72
  //     }
  //   } else if (functionn == Constant.F_OUTPUT_COMAND) {
  //     //Uscite Centrale
  //     //{"ty":0,"u":0,"idx":1,"name":"uno","rule":7,"state":0}
  //     if (ty == 0 && u == 0) {
  //       return idx
  //     }

  //     //Uscite Seriale
  //     //{"name":"Ing_1_0_1","ex":0,"en":0,"ty":1,"u":0,"idx":1,"state":0}
  //     if (ty == 1 && u == 0) {
  //       return idx + 8
  //     }

  //     //Uscite Radio
  //     //{"name":"Sensore_1_1","ex":0,"en":0,"ty":2,"u":0,"idx":1,"state":1}
  //     if (ty == 2 && u == 0) {
  //       return u * 4 + idx + 40
  //     }
  //   } else if (functionn == Constant.F_UNIT) {
  //     let tmp = 0

  //     //periferiche:

  //     //centrale 0 / 1 ???Fede!!

  //     //Periferiche Seriali
  //     if (ty == 1) {
  //       //concentratore 8
  //       if (u == 1) {
  //         tmp = 8
  //       }

  //       //tastiera 24 + id
  //       if (u == 2) {
  //         tmp = 24 + idx
  //       }

  //       //sirena 40 + id
  //       if (u == 3) {
  //         tmp = 40 + idx
  //       }

  //       //rfid 48 +id
  //       if (u == 4) {
  //         tmp = 48 + idx
  //       }

  //       //c24    56
  //       if (u == 5) {
  //         tmp = 56
  //       }

  //       //filo(aggiungo 101  al numero sottostante)
  //       return 101 + tmp
  //     }

  //     /*
  //                { "f":"unit","a":"list","p":[{ "ty":1,"ki":2,"idx":0,"name":"Tastiera","en":1}]}

  //               CONCENTRATORI	1
  //               KEYBOARD	2
  //               SIRENS	3
  //               RFID	4
  //               ATTUATOR	5
  //               SENSOR 6

  //               radio (  aggiungo 301  al numero sottostante )
  //               rcmd 1-8 //fede!! in periferiche radio non e'presente radiocomando ...

  //               sensori(6) 32 -64
  //               tastiere(2)    64 -65
  //               sirene(3)  69 -70
  //               b94(5) 73

  //               */

  //     //Periferiche Radio
  //     if (ty == 2) {
  //       //tastiere
  //       if (u == 2) {
  //         tmp = 64 + idx
  //       }

  //       //sirene
  //       if (u == 3) {
  //         tmp = 69 + idx
  //       }

  //       //b94 attuatori
  //       if (u == 5) {
  //         tmp = 73
  //       }

  //       //sensori
  //       if (u == 6) {
  //         tmp = 32 + idx
  //       }

  //       return 301 + tmp
  //     }
  //   } else if (
  //     functionn == Constant.F_USERS ||
  //     functionn == Constant.F_SERVICE_AREA
  //   ) {
  //     //utenti idx = id 0-8
  //     //aree idx = id 0-2
  //     if (ty == 0 && u == 0) {
  //       return idx
  //     }
  //   }

  //   return 0
  // }

  static getFileNameForUpdate(subtype: string, filename: string) {
    const fileName: string =
      subtype != '' ? filename.replace('.json', `_${subtype}.json`) : filename

    return fileName
  }

  static displayError(msg: string, colors: any, isError: boolean = false) {
    if (isError) {
      return toast.error(msg)
    }

    return toast.success(msg, {
      style: {
        padding: '16px',
        color: colors.primary.main,
        border: `1px solid ${colors.primary.main}`
      },
      iconTheme: {
        primary: colors.primary.main,
        secondary: ''
      }
    })
  }

  static decimalToHex(d: number, padding: number) {
    let hex = Number(d).toString(16)
    padding =
      typeof padding === 'undefined' || padding === null
        ? (padding = 2)
        : padding

    while (hex.length < padding) {
      hex = `0${hex}`
    }

    return hex
  }

  static IsNumeric = (value: string) => {
    return /^\d+$/.test(value)
  }
}

export default Utility
