import Constant from '../utility/Constants'
import IMessage from './messages'

const serList = [
  // {
  //   label: 'Predefinita',
  //   value: 0
  // },
  {
    label: 'Entrambe',
    value: 0
  },
  {
    label: 'Seriale 1',
    value: 1
  },
  {
    label: 'Seriale 2',
    value: 2
  }
]

const hornList = [
  {
    label: ' - ',
    value: 0
  },
  {
    label: 'Allarme',
    value: 1
  },
  {
    label: 'Incendio',
    value: 2
  },
  {
    label: 'Gong',
    value: 3
  }
]

const cmdOutStateList = [
  {
    label: 'Attiva',
    value: 1
  },
  {
    label: 'Disattiva',
    value: 2
  },
  {
    label: 'Toggled',
    value: 3
  }
]

// TODO:2 add and API to get ...
const operatorList = [
  {name: 'Coop Voce', apn: 'internet.coopvoce.it'},
  {name: 'Fastweb', apn: 'apn.fastweb.it'},
  {name: 'Gesco (SIM)', apn: Constant.APN_GESCO},
  {name: 'Ho Mobile', apn: 'web.ho-mobile.it'},
  {name: 'Iliad', apn: 'iliad'},
  {name: 'TIM', apn: 'ibox.tim.it'},
  {name: 'Vodafone (Vecchie)', apn: 'web.omnitel.it'},
  {name: 'Vodafone (Nuove)', apn: 'mobile.vodafone.it'},
  {name: 'Wind Tre/Very Mobile', apn: 'internet.it'},
  {name: 'PosteMobile', apn: 'wap.postemobile.it'},
  {name: 'Wind', apn: 'internet.wind'},
  {name: '3 H3G', apn: 'tre.it'}
]

const connList = [
  // {
  //   label: ' ',
  //   value: 0,
  // },
  {
    label: 'Normalmente chiuso',
    value: 1,
    key: 1
  },
  {
    label: 'Singolo bilanciamento',
    value: 2,
    key: 2
  },
  {
    label: 'Doppio bilanciamento',
    value: 3,
    key: 3
  }
]

const typeListOld = [
  {
    label: 'Istantaneo',
    value: 1,
    key: 1
  },

  {
    label: 'Ritardato 1',
    value: 2,
    key: 2
  },
  {
    label: 'Ritardato 2',
    value: 3,
    key: 3
  },

  {
    label: 'Istantaneo Interno',
    value: 4,
    key: 4
  },
  {
    label: 'Allarme silenzioso',
    value: 5,
    key: 5
  },

  {
    label: 'Comando 1',
    value: 6,
    key: 6
  },
  {
    label: 'Comando 2',
    value: 7,
    key: 7
  },
  {
    label: 'Comando 3',
    value: 8,
    key: 8
  },
  {
    label: 'Comando 4',
    value: 9,
    key: 9
  },

  {
    label: 'Allagamento',
    value: 10,
    key: 10
  },
  {
    label: 'Incendio',
    value: 11,
    key: 11
  },

  {
    label: 'Comando esterno 1',
    value: 12,
    key: 12
  },
  {
    label: 'Comando esterno 2',
    value: 13,
    key: 13
  },
  {
    label: 'Comando esterno 3',
    value: 14,
    key: 14
  },

  {
    label: 'Gong',
    value: 15,
    key: 15
  }
]

const typeListIn = [
  {
    label: 'Istantaneo',
    value: 1,
    key: 1
  },

  {
    label: 'Ritardato 1',
    value: 2,
    key: 2
  },
  {
    label: 'Ritardato 2',
    value: 3,
    key: 3
  },

  {
    label: 'Istantaneo Interno',
    value: 4,
    key: 4
  },
  {
    label: 'Allarme silenzioso',
    value: 5,
    key: 5
  },

  {
    label: 'Comando 1 per pulsante',
    value: 6,
    key: 6
  },
  {
    label: 'Comando 2 per pulsante',
    value: 7,
    key: 7
  },
  {
    label: 'Comando 3 per pulsante',
    value: 8,
    key: 8
  },
  {
    label: 'Comando 4 per pulsante',
    value: 9,
    key: 9
  },

  {
    label: 'Allagamento',
    value: 10,
    key: 10
  },
  {
    label: 'Incendio',
    value: 11,
    key: 11
  },

  {
    label: 'Comando esterno 1',
    value: 12,
    key: 12
  },
  {
    label: 'Comando esterno 2',
    value: 13,
    key: 13
  },
  {
    label: 'Comando esterno 3',
    value: 14,
    key: 14
  },

  {
    label: 'Istantaneo + Gong',
    value: 15,
    key: 15
  },

  {
    label: 'Comando 1 per interruttore',
    value: 16,
    key: 16
  },
  {
    label: 'Comando 2 per interruttore',
    value: 17,
    key: 17
  },
  {
    label: 'Comando 3 per interruttore',
    value: 18,
    key: 18
  },
  {
    label: 'Comando 4 per interruttore',
    value: 19,
    key: 19
  }
]

const typeList_V1_38 = [
  ...typeListIn,
  {
    label: 'Malore',
    value: Constant.TYPE_INPUT_MALORE,
    key: Constant.TYPE_INPUT_MALORE
  }
]

const typeList_V1_41 = [
  ...typeList_V1_38,
  {
    label: 'Rapina',
    value: 21,
    key: 21
  }
]

const typeList_V1_49 = [
  ...typeList_V1_41
  // {
  //   label: 'Allarme 1',
  //   value: 55,
  //   key: 22,
  // },
  // {
  //   label: 'Allarme 2',
  //   value: 54,
  //   key: 23,
  // },
  // {
  //   label: 'Allarme 3',
  //   value: 53,
  //   key: 24,
  // },
]

// Output editing typology
const outputTypologyOld = [
  {
    label: 'Generica',
    value: 0,
    isDefEnabled: 0
  },
  {
    label: 'Allarme',
    value: 1,
    isDefEnabled: 0
  },
  {
    label: 'Preallarme 1',
    value: 2,
    isDefEnabled: 0
  },
  {
    label: 'Preallarme 2',
    value: 3,
    isDefEnabled: 0
  },
  {
    label: 'Pronto di sistema',
    value: 4,
    isDefEnabled: 0
  },
  // {
  //   label: 'Servizio completo',
  //   value: 5,
  //   isDefEnabled: 0,
  // },
  {
    label: 'Stato di servizio',
    value: 6,
    isDefEnabled: 0
  },
  {
    label: 'Comando 1',
    value: 7,
    isDefEnabled: 1
  },
  {
    label: 'Comando 2',
    value: 8,
    isDefEnabled: 1
  },
  {
    label: 'Comando 3',
    value: 9,
    isDefEnabled: 1
  },
  {
    label: 'Comando 4',
    value: 10,
    isDefEnabled: 1
  },
  {
    label: 'Allarme silenzioso',
    value: 11,
    isDefEnabled: 1
  },
  {
    label: 'Allarme Servizio 1',
    value: 12,
    isDefEnabled: 1
  },
  {
    label: 'Allarme Servizio 2',
    value: 13,
    isDefEnabled: 1
  },
  {
    label: 'Allarme Servizio 3',
    value: 14,
    isDefEnabled: 1
  },
  {
    label: 'Stato di Servizio 1',
    value: 15,
    isDefEnabled: 1
  },
  {
    label: 'Stato di Servizio 2',
    value: 16,
    isDefEnabled: 1
  },
  {
    label: 'Stato di Servizio 3',
    value: 17,
    isDefEnabled: 1
  }
]

const outputTypology_V1_25 = [
  {
    label: 'Generica',
    value: 0,
    isDefEnabled: 0
  },
  {
    label: 'Allarme',
    value: 1,
    isDefEnabled: 0
  },
  {
    label: 'Preallarme 1',
    value: 2,
    isDefEnabled: 0
  },
  {
    label: 'Preallarme 2',
    value: 3,
    isDefEnabled: 0
  },
  {
    label: 'Pronto di sistema',
    value: 4,
    isDefEnabled: 0
  },
  // {
  //   label: 'Servizio completo',
  //   value: 5,
  //   isDefEnabled: 0,
  // },
  {
    label: 'Stato di servizio',
    value: 6,
    isDefEnabled: 0
  },
  {
    label: 'Comando 1',
    value: 7,
    isDefEnabled: 1
  },
  {
    label: 'Comando 2',
    value: 8,
    isDefEnabled: 1
  },
  {
    label: 'Comando 3',
    value: 9,
    isDefEnabled: 1
  },
  {
    label: 'Comando 4',
    value: 10,
    isDefEnabled: 1
  },
  {
    label: 'Allarme silenzioso',
    value: 11,
    isDefEnabled: 1
  },
  {
    label: 'Allarme Servizio 1',
    value: 12,
    isDefEnabled: 1
  },
  {
    label: 'Allarme Servizio 2',
    value: 13,
    isDefEnabled: 1
  },
  {
    label: 'Allarme Servizio 3',
    value: 14,
    isDefEnabled: 1
  },
  {
    label: 'Stato di Servizio 1',
    value: 15,
    isDefEnabled: 1
  },
  {
    label: 'Stato di Servizio 2',
    value: 16,
    isDefEnabled: 1
  },
  {
    label: 'Stato di Servizio 3',
    value: 17,
    isDefEnabled: 1
  }
  /*
  {
    label: 'Termostato 1',
    value: 18,
    isDefEnabled: 1,
  },
  {
    label: 'Termostato 2',
    value: 19,
    isDefEnabled: 1,
  },
  {
    label: 'Termostato 3',
    value: 20,
    isDefEnabled: 1,
  },
  {
    label: 'Termostato 4',
    value: 21,
    isDefEnabled: 1,
  }, */
]

const outputTypology_V1_50 = [
  ...outputTypology_V1_25,
  {
    label: 'Rapina',
    value: Constant.OUT_TY_ROBBERY,
    isDefEnabled: 1
  },
  {
    label: 'Malore',
    value: Constant.OUT_TY_COLLAPSE,
    isDefEnabled: 1
  }
]

// Output editing default (disattivata attivata)
const outputDefault = [
  {
    label: 'Disattivata',
    value: 0
  },
  {
    label: 'Attivata',
    value: 1
  }
]

// Output editing mode (diretto, invertito)
const outputMode = [
  {
    label: 'Diretto',
    value: 'd'
  },
  {
    label: 'Invertito',
    value: 'i'
  }
]

const typeInputOutput = [
  {
    label: ' ',
    value: -1
  },
  {
    label: 'Centrale',
    value: 0
  },
  {
    label: 'Seriale',
    value: 1
  },
  {
    label: 'Radio',
    value: 2
  }
]

const keyType = [
  {
    label: ' ',
    value: 0
  },

  {
    label: 'Servizio 1',
    value: 65
  },
  {
    label: 'Servizio 2',
    value: 64
  },
  {
    label: 'Servizio 3',
    value: 63
  },

  {
    label: 'Comando 1',
    value: 94
  },
  {
    label: 'Comando 2',
    value: 93
  },
  {
    label: 'Comando 3',
    value: 92
  },
  {
    label: 'Comando 4',
    value: 91
  }
]

const timingHornList = [
  {
    label: 'Disabilitata',
    value: 0
  },
  {
    label: '15 minuti',
    value: 15
  },
  {
    label: '30 minuti',
    value: 30
  },
  {
    label: '45 minuti',
    value: 45
  },
  {
    label: '1 ora',
    value: 60
  }
]

const timingSensorList = [
  {
    label: 'Disabilitata',
    value: 0
  },
  {
    label: '1 Ora',
    value: 1
  },
  {
    label: '2 Ore',
    value: 2
  },
  {
    label: '3 Ore',
    value: 3
  },
  {
    label: '4 Ore',
    value: 4
  }
]

const typeOutputState = [
  {
    label: 'Disattiva',
    value: 0
  },
  {
    label: 'Attiva',
    value: 1
  }
]

const typeOutput = [
  {
    label: ' - ',
    value: 0
  },
  {
    label: 'Uscita 001',
    value: 1
  },
  {
    label: 'Uscita 002',
    value: 2
  },
  {
    label: 'Uscita 003',
    value: 3
  },
  {
    label: 'Uscita 004',
    value: 4
  },
  {
    label: 'Uscita 101',
    value: 101
  },
  {
    label: 'Uscita 102',
    value: 102
  },
  {
    label: 'Uscita 103',
    value: 103
  },
  {
    label: 'Uscita 104',
    value: 104
  },
  {
    label: 'Uscita 201',
    value: 201
  },
  {
    label: 'Uscita 202',
    value: 202
  },
  {
    label: 'Uscita 203',
    value: 203
  },
  {
    label: 'Uscita 204',
    value: 204
  }
]

const typeWifi = [
  {
    label: 'Attiva',
    value: 'on' //Cannot use constant
  },
  {
    label: 'Access Point',
    value: 'ap'
  },
  {
    label: 'Disattivata',
    value: 'off'
  }
]

const msgVersionGetObj: IMessage = {
  f: Constant.F_VERSION,
  a: Constant.A_GET
}

const msgGetBlockStatusObj: IMessage = {
  f: Constant.F_BLOCK,
  a: Constant.A_GET
}

const msgListAreasObj: IMessage = {
  f: Constant.F_SERVICE_AREA,
  a: Constant.A_GET_LIST
}

const msgListOutputObj: IMessage = {
  f: Constant.F_OUTPUT_COMAND,
  a: Constant.A_GET_LIST,
  p: {ty: -1}
}

const msgGetSerialSIMObj: IMessage = {
  f: Constant.F_SIM,
  a: Constant.A_GET
}

const msgLstInputsObj: IMessage = {
  f: Constant.F_INPUT_SENSOR,
  a: Constant.A_GET_LIST
}

const defaultEmptyAreaObj = {idx: -1, name: ''}

const msgListUsersObj: IMessage = {
  f: Constant.F_USERS,
  a: Constant.A_GET_LIST
}

const defaultEmptyUserObj = {
  idx: -1,
  name: '',
  pincode: '',
  conf: 0,
  rc: 0,
  tag: 0,
  instal: 0,
  service: {s1: 0, s2: 0, s3: 0},
  rdf: {
    b5: Constant.CMD1_DEFAULT,
    b6: Constant.CMD2_DEFAULT,
    b7: Constant.CMD3_DEFAULT
  }
}

const defaultEmptyCameraObj = {
  ID: -1,
  Name: '',
  Description: '',
  Note: '',
  Rtsp1: '',
  Rtsp2: '',
  Rtsp3: '',
  Ip: '',
  Mac: '',
  UserName: '',
  Password: '',
  rdf: {
    b5: Constant.CMD1_DEFAULT,
    b6: Constant.CMD2_DEFAULT,
    b7: Constant.CMD3_DEFAULT
  }
}

//B83
const lstColors = [
  {id: 0xffffff, value: 0, label: 'Nessuno', color: Constant.COLOR_BLACK},
  {id: 0x1565c0, value: 1, label: 'Blu', color: Constant.COLOR_BLACK},
  {id: 0xc62828, value: 2, label: 'Rossa', color: Constant.COLOR_BLACK},
  {id: 0x43a047, value: 3, label: 'Verde', color: Constant.COLOR_BLACK}
]

const lstLampeggioStato = [
  {id: 0xffffff, value: 0, label: 'Disabilitato', color: Constant.COLOR_BLACK},
  {
    id: 0x1565c0,
    value: 1,
    label: 'Solo cambio stato',
    color: Constant.COLOR_BLACK
  },
  {
    id: 0xc62828,
    value: 2,
    label: 'Lampi rossi in servizio',
    color: Constant.COLOR_BLACK
  },
  {
    id: 0x43a047,
    value: 3,
    label: 'Flash in servizio (modalità ponteggio)',
    color: Constant.COLOR_BLACK
  }
]

const lstIndirizzi = [
  {value: 1, label: 'Indirizzo 1'},
  {value: 2, label: 'Indirizzo 2'}
]

//{"f":"users","a":"get",
//"p":{"idx":1,"ret":"","name":"pippo",
//"conf":1,"instal":0,"modinst":1,"enaserv":0,
//"service":{"s1":1,"s2":1,"s3":1},
//"tagf":{"b1":65,"b2":64,"b3":63,"b4":94},
//"rdf":{"b0":73,"b1":65,"b2":64,"b3":63,"b4":87,"b5":94,"b6":93,"b7":92},
//"en":1,"rc":0,"tag":0}}

//{"f":"users","a":"save","c":"112234",
//"p":{"idx":-1,"name":"pippo2","pincode":"888888",
//"conf":1,"rc":0,"tag":0,"instal":0,
//"service":{"s1":1,"s2":1,"s3":1},
//"rdf":{"b5":94,"b6":93,"b7":92},
//"en":1,"modinst":1,"permission":null}}

export {
  serList,
  hornList,
  cmdOutStateList,
  operatorList,
  msgVersionGetObj,
  msgGetBlockStatusObj,
  msgListAreasObj,
  defaultEmptyAreaObj,
  msgListUsersObj,
  defaultEmptyUserObj,
  msgListOutputObj,
  msgGetSerialSIMObj,
  keyType,
  typeInputOutput,
  timingHornList,
  timingSensorList,
  connList,
  typeListOld,
  typeListIn,
  typeList_V1_38,
  typeList_V1_41,
  typeList_V1_49,
  outputTypologyOld,
  outputTypology_V1_25,
  outputTypology_V1_50,
  outputDefault,
  outputMode,
  typeOutputState,
  typeOutput,
  typeWifi,
  lstColors,
  lstLampeggioStato,
  lstIndirizzi,
  msgLstInputsObj,
  defaultEmptyCameraObj
}
