// ** Icons Import
import {Heart} from 'react-feather'
import isDev from '@src/utility/DevDetect'

const Footer = () => {
  return (
    <p className="clearfix mb-0">
      <span className="float-md-start d-block d-md-inline-block mt-25">
        © {new Date().getFullYear()}{' '}
        <a
          href="https://www.gesco.it"
          target="_blank"
          rel="noopener noreferrer">
          Gesco s.r.l.
        </a>
        <span className="d-none d-sm-inline-block">
          , Tutti i diritti riservati (v. {process.env.REACT_APP_VERSION}
          {isDev() ? ' !!!!DEV!!!!' : ''})
        </span>
      </span>
    </p>
  )
}

export default Footer
