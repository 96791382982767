import ColorsGesco from './ColorsGesco'
import isDev from './DevDetect'

class Constant {
  public static readonly GATEWAY_IP: string = '' //use proxy in package.json

  public static readonly DEFAULT_NUMERIC_CODE: string = '123456'
  public static readonly EMPTY_NUMERIC_CODE: string = '@@@@@@'

  public static readonly NUM_MAX_AREAS: number = 16

  public static readonly API_SERVER: string = isDev()
    ? ''
    : 'https://api.ubiway.it'
  public static readonly WS_API_SERVER: string = Constant.API_SERVER

  public static readonly CHECK_ONLINE: string = 'CheckOnlineWebApiConnTypeWeb'

  // mq
  public static readonly MQTT_SERVER: string = 'mq.ubiway.com'
  public static readonly MQTT_PORT: number = 8084
  public static readonly MQTT_PATH: string = '/mqtt'
  public static readonly MQTT_QOS: number = 1
  public static readonly MQTT_RESP: string = 'resp'
  public static readonly MQTT_PUSH: string = 'push'
  public static readonly MQTT_CONFIG: string = 'config'

  public static readonly DEFAULT_FW_NAME: string = 'firmware.json'
  public static readonly DEFAULT_FW_NAME_V2: string = 'firmware_v2.json'
  public static readonly TEST_FW_NAME: string = 'test.json'
  public static readonly TEST_FW_NAME_V2: string = 'test_v2.json'

  public static COLOR_PRIMARY: string = ColorsGesco.blue800
  public static COLOR_STATUS_BAR: string = ColorsGesco.blue800
  public static COLOR_ACCENT: string = ColorsGesco.orange400
  public static COLOR_RED: string = ColorsGesco.red800
  public static COLOR_YELLOW: string = ColorsGesco.yellow700
  public static COLOR_GREEN: string = ColorsGesco.green600
  public static COLOR_LIGHT_GREY: string = ColorsGesco.grey200
  public static COLOR_GREY: string = ColorsGesco.grey600
  public static COLOR_WHITE: string = ColorsGesco.white
  public static COLOR_BLACK: string = ColorsGesco.black

  public static COLOR_GREY_ICON = 'rgba(82, 82, 86, 5)'

  public static COLOR_GREY_PLACEHOLDER = '#9EA0A4'

  public static readonly APN_GESCO: string = 'iot.netmon.vodafone.it'

  public static readonly MAINTENANCE_BLOCK: string = 'block'
  public static readonly MAINTENANCE_REBOOT: string = 'reboot'
  public static readonly MAINTENANCE_UPDATE: string = 'update'
  public static readonly MAINTENANCE_CONFIG: string = 'config'
  public static readonly MAINTENANCE_MEMORY: string = 'memory'
  public static readonly MAINTENANCE_FACTORY: string = 'factory'

  public static readonly PATH_SEQUENCES: string = '/sequences'
  public static readonly PATH_ALARM_SYSTEM: string = '/alarmsystem'

  public static readonly PAGE_SIZE: number = 500

  // action
  public static A_GET: string = 'get'
  public static A_GET_LIST: string = 'list'
  public static A_SAVE: string = 'save'
  public static A_NEW: string = 'new'
  public static A_STATUS: string = 'status'
  public static A_CHANGE: string = 'change'
  public static A_LEARN: string = 'learn' // apprend radio sensor
  public static A_EXEC: string = 'exec'
  public static A_CHECK: string = 'check'
  public static A_EXCLUDE: string = 'exclude'
  public static A_DETAIL: string = 'detail'
  public static A_SEND: string = 'send'
  public static A_CALL: string = 'call'
  public static A_DO: string = 'do'
  public static A_DO_LOCAL: string = 'do_local'
  public static A_REMOTE: string = 'remote'
  public static A_FACTORY: string = 'factory'
  public static A_ADD_RADIO_C: string = 'add_radio_control'
  public static A_ADD_TAG: string = 'add_tag'
  public static A_DEL_RADIO_C: string = 'del_radio_control'
  public static A_DEL_TAG: string = 'del_tag'
  public static A_DEL: string = 'delete'
  public static A_DEL_RADIO_LEARN: string = 'del_radio_learn'
  public static A_CHECK_LEARN_RC: string = 'check_learn_rc'
  public static A_CHECK_LEARN_TAG: string = 'check_learn_tag'
  public static A_CHECK_LEARN: string = 'check_learn'
  public static A_SCREENSHOT: string = 'screenshot'

  // function
  public static F_VERSION: string = 'version'
  public static F_CONFIG0: string = 'config0'
  public static F_CONFIG: string = 'config'
  public static F_TOKEN: string = 'token'
  public static F_BLOCK: string = 'block'
  public static F_CELL: string = 'cell' // contacts
  public static F_ARM_DISARM: string = 'arm'
  public static F_PUSH: string = 'push'
  public static F_SCAN_WIFI: string = 'scan_wifi'
  public static F_SERVICE_AREA: string = 'areas'
  public static F_USERS: string = 'users'
  public static F_INPUT_SENSOR: string = 'input'
  public static F_INCL_SENSOR: string = 'incl'
  public static F_OUTPUT_COMAND: string = 'output'
  public static F_OUTPUT_LIST: string = 'output_cmd'
  public static F_UNIT: string = 'unit'
  public static F_MESSAGE: string = 'msg' // common message
  public static F_HORN: string = 'horn' // sirena
  public static F_COMMAND: string = 'cmd'
  public static F_TIMING: string = 'temp'
  public static F_SEQ: string = 'seq'
  public static F_MAINTENANCE: string = 'maintenance'
  public static F_STATUS: string = 'status'
  public static F_REBOOT: string = 'reboot'
  public static F_MEMORY: string = 'memory'
  public static F_UPGRADE: string = 'upgrade'
  public static F_SIA: string = 'sia'
  public static F_BACKUP: string = 'backup'
  public static F_RESTORE: string = 'restore'
  public static F_PSS: string = 'pss'
  public static F_EVENTS: string = 'events'
  public static F_TEMPERATURE: string = 'termo'
  public static F_SIM: string = 'sim'
  public static F_CAMERAS: string = 'cameras'
  public static F_IP: string = 'ip'
  public static F_ONVIF: string = 'onvif'
  public static F_RTSP: string = 'rtsp'
  public static F_ALARM_SYSTEM: string = 'alarmsystem'
  public static F_WIFI: string = 'wifi'

  public static START_LEARN: string = 'Start Learn'

  public static WIFI_LABEL: string = 'Rete WiFi'
  public static LTE_LABEL: string = 'Rete LTE'
  public static UTENTI_LABEL: string = 'Utenti'
  public static MANUTENZIONE_LABEL: string = 'Manutenzione'
  public static INGRESSI_LABEL: string = 'Ingressi'
  public static ING_LABEL: string = 'Ing.'
  public static USCITE_LABEL: string = 'Uscite'
  public static PERIFERICHE_LABEL: string = 'Periferiche'
  public static PER_LABEL: string = 'Per.'
  public static INCL_ESCL_LABEL: string = 'Escludi/Includi Ingresso'
  public static INT_PSS: string = 'Integrazione PSS'

  public static MSG_LEARN: string = 'Apprendimento in corso attendere'
  public static MSG_LEARN_RADIO: string =
    'Apprendimento Radiocomando in corso attendere'

  public static MSG_LIST_INPUTS: string = 'Caricamento ingressi in corso'
  public static MSG_LIST_OUTPUTS: string = 'Caricamento uscite in corso'

  public static DEFAULT_USER: string = '@@@@@@'

  public static CMD1_DEFAULT: number = 94
  public static CMD2_DEFAULT: number = 93
  public static CMD3_DEFAULT: number = 92
  public static CMD1_FORCED_SERVICE: number = 72
  public static CMD2_FORCED_SERVICE: number = 71
  public static CMD3_FORCED_SERVICE: number = 70

  public static CMD5_PANIC: number = 87

  public static OUT_TY_ROBBERY: number = 22
  public static OUT_TY_COLLAPSE: number = 23

  public static B71_SENSOR_TP: number = 20
  public static S36_SERIAL_HORN: string = 'S36'
  public static B83_RADIO_HORN: string = 'B83'
  public static B24_EXTERNAL_B20: string = 'B24' //B20 da esterno

  public static TYPE_INPUT_MALORE: number = 20

  public static WIFI_ON: string = 'on'
  public static WIFI_OFF: string = 'off'
  public static WIFI_AP_MODE: string = 'ap'
  public static WIFI: string = 'wifi'

  public static IT1: string = 'IT1'

  public static VB1: string = 'VB1'
}

export default Constant
