class ColorsGesco {
  public static readonly red50 = '#ffebee'
  public static readonly red100 = '#ffcdd2'
  public static readonly red200 = '#ef9a9a'
  public static readonly red300 = '#e57373'
  public static readonly red400 = '#ef5350'
  public static readonly red500 = '#f44336'
  public static readonly red600 = '#e53935'
  public static readonly red700 = '#d32f2f'
  public static readonly red800 = '#C62828'
  public static readonly red900 = '#b71c1c'
  public static readonly redA100 = '#ff8a80'
  public static readonly redA200 = '#ff5252'
  public static readonly redA400 = '#ff1744'
  public static readonly redA700 = '#d50000'
  public static readonly pink50 = '#fce4ec'
  public static readonly pink100 = '#f8bbd0'
  public static readonly pink200 = '#f48fb1'
  public static readonly pink300 = '#f06292'
  public static readonly pink400 = '#ec407a'
  public static readonly pink500 = '#e91e63'
  public static readonly pink600 = '#d81b60'
  public static readonly pink700 = '#c2185b'
  public static readonly pink800 = '#ad1457'
  public static readonly pink900 = '#880e4f'
  public static readonly pinkA100 = '#ff80ab'
  public static readonly pinkA200 = '#ff4081'
  public static readonly pinkA400 = '#f50057'
  public static readonly pinkA700 = '#c51162'
  public static readonly purple50 = '#f3e5f5'
  public static readonly purple100 = '#e1bee7'
  public static readonly purple200 = '#ce93d8'
  public static readonly purple300 = '#ba68c8'
  public static readonly purple400 = '#ab47bc'
  public static readonly purple500 = '#9c27b0'
  public static readonly purple600 = '#8e24aa'
  public static readonly purple700 = '#7b1fa2'
  public static readonly purple800 = '#6a1b9a'
  public static readonly purple900 = '#4a148c'
  public static readonly purpleA100 = '#ea80fc'
  public static readonly purpleA200 = '#e040fb'
  public static readonly purpleA400 = '#d500f9'
  public static readonly purpleA700 = '#aa00ff'
  public static readonly deepPurple50 = '#ede7f6'
  public static readonly deepPurple100 = '#d1c4e9'
  public static readonly deepPurple200 = '#b39ddb'
  public static readonly deepPurple300 = '#9575cd'
  public static readonly deepPurple400 = '#7e57c2'
  public static readonly deepPurple500 = '#673ab7'
  public static readonly deepPurple600 = '#5e35b1'
  public static readonly deepPurple700 = '#512da8'
  public static readonly deepPurple800 = '#4527a0'
  public static readonly deepPurple900 = '#311b92'
  public static readonly deepPurpleA100 = '#b388ff'
  public static readonly deepPurpleA200 = '#7c4dff'
  public static readonly deepPurpleA400 = '#651fff'
  public static readonly deepPurpleA700 = '#6200ea'
  public static readonly indigo50 = '#e8eaf6'
  public static readonly indigo100 = '#c5cae9'
  public static readonly indigo200 = '#9fa8da'
  public static readonly indigo300 = '#7986cb'
  public static readonly indigo400 = '#5c6bc0'
  public static readonly indigo500 = '#3f51b5'
  public static readonly indigo600 = '#3949ab'
  public static readonly indigo700 = '#303f9f'
  public static readonly indigo800 = '#283593'
  public static readonly indigo900 = '#1a237e'
  public static readonly indigoA100 = '#8c9eff'
  public static readonly indigoA200 = '#536dfe'
  public static readonly indigoA400 = '#3d5afe'
  public static readonly indigoA700 = '#304ffe'
  public static readonly blue50 = '#e3f2fd'
  public static readonly blue100 = '#bbdefb'
  public static readonly blue200 = '#90caf9'
  public static readonly blue300 = '#64b5f6'
  public static readonly blue400 = '#42a5f5'
  public static readonly blue500 = '#2196f3'
  public static readonly blue600 = '#1e88e5'
  public static readonly blue700 = '#1976d2'
  public static readonly blue800 = '#1565c0'
  public static readonly blue900 = '#0d47a1'
  public static readonly blueA100 = '#82b1ff'
  public static readonly blueA200 = '#448aff'
  public static readonly blueA400 = '#2979ff'
  public static readonly blueA700 = '#2962ff'
  public static readonly lightBlue50 = '#e1f5fe'
  public static readonly lightBlue100 = '#b3e5fc'
  public static readonly lightBlue200 = '#81d4fa'
  public static readonly lightBlue300 = '#4fc3f7'
  public static readonly lightBlue400 = '#29b6f6'
  public static readonly lightBlue500 = '#03a9f4'
  public static readonly lightBlue600 = '#039be5'
  public static readonly lightBlue700 = '#0288d1'
  public static readonly lightBlue800 = '#0277bd'
  public static readonly lightBlue900 = '#01579b'
  public static readonly lightBlueA100 = '#80d8ff'
  public static readonly lightBlueA200 = '#40c4ff'
  public static readonly lightBlueA400 = '#00b0ff'
  public static readonly lightBlueA700 = '#0091ea'
  public static readonly cyan50 = '#e0f7fa'
  public static readonly cyan100 = '#b2ebf2'
  public static readonly cyan200 = '#80deea'
  public static readonly cyan300 = '#4dd0e1'
  public static readonly cyan400 = '#26c6da'
  public static readonly cyan500 = '#00bcd4'
  public static readonly cyan600 = '#00acc1'
  public static readonly cyan700 = '#0097a7'
  public static readonly cyan800 = '#00838f'
  public static readonly cyan900 = '#006064'
  public static readonly cyanA100 = '#84ffff'
  public static readonly cyanA200 = '#18ffff'
  public static readonly cyanA400 = '#00e5ff'
  public static readonly cyanA700 = '#00b8d4'
  public static readonly teal50 = '#e0f2f1'
  public static readonly teal100 = '#b2dfdb'
  public static readonly teal200 = '#80cbc4'
  public static readonly teal300 = '#4db6ac'
  public static readonly teal400 = '#26a69a'
  public static readonly teal500 = '#009688'
  public static readonly teal600 = '#00897b'
  public static readonly teal700 = '#00796b'
  public static readonly teal800 = '#00695c'
  public static readonly teal900 = '#004d40'
  public static readonly tealA100 = '#a7ffeb'
  public static readonly tealA200 = '#64ffda'
  public static readonly tealA400 = '#1de9b6'
  public static readonly tealA700 = '#00bfa5'
  public static readonly green50 = '#e8f5e9'
  public static readonly green100 = '#c8e6c9'
  public static readonly green200 = '#a5d6a7'
  public static readonly green300 = '#81c784'
  public static readonly green400 = '#66bb6a'
  public static readonly green500 = '#4caf50'
  public static readonly green600 = '#43a047'
  public static readonly green700 = '#388e3c'
  public static readonly green800 = '#2e7d32'
  public static readonly green900 = '#1b5e20'
  public static readonly greenA100 = '#b9f6ca'
  public static readonly greenA200 = '#69f0ae'
  public static readonly greenA400 = '#00e676'
  public static readonly greenA700 = '#00c853'
  public static readonly lightGreen50 = '#f1f8e9'
  public static readonly lightGreen100 = '#dcedc8'
  public static readonly lightGreen200 = '#c5e1a5'
  public static readonly lightGreen300 = '#aed581'
  public static readonly lightGreen400 = '#9ccc65'
  public static readonly lightGreen500 = '#8bc34a'
  public static readonly lightGreen600 = '#7cb342'
  public static readonly lightGreen700 = '#689f38'
  public static readonly lightGreen800 = '#558b2f'
  public static readonly lightGreen900 = '#33691e'
  public static readonly lightGreenA100 = '#ccff90'
  public static readonly lightGreenA200 = '#b2ff59'
  public static readonly lightGreenA400 = '#76ff03'
  public static readonly lightGreenA700 = '#64dd17'
  public static readonly lime50 = '#f9fbe7'
  public static readonly lime100 = '#f0f4c3'
  public static readonly lime200 = '#e6ee9c'
  public static readonly lime300 = '#dce775'
  public static readonly lime400 = '#d4e157'
  public static readonly lime500 = '#cddc39'
  public static readonly lime600 = '#c0ca33'
  public static readonly lime700 = '#afb42b'
  public static readonly lime800 = '#9e9d24'
  public static readonly lime900 = '#827717'
  public static readonly limeA100 = '#f4ff81'
  public static readonly limeA200 = '#eeff41'
  public static readonly limeA400 = '#c6ff00'
  public static readonly limeA700 = '#aeea00'
  public static readonly yellow50 = '#fffde7'
  public static readonly yellow100 = '#fff9c4'
  public static readonly yellow200 = '#fff59d'
  public static readonly yellow300 = '#fff176'
  public static readonly yellow400 = '#ffee58'
  public static readonly yellow500 = '#ffeb3b'
  public static readonly yellow600 = '#fdd835'
  public static readonly yellow700 = '#fbc02d'
  public static readonly yellow800 = '#f9a825'
  public static readonly yellow900 = '#f57f17'
  public static readonly yellowA100 = '#ffff8d'
  public static readonly yellowA200 = '#ffff00'
  public static readonly yellowA400 = '#ffea00'
  public static readonly yellowA700 = '#ffd600'
  public static readonly amber50 = '#fff8e1'
  public static readonly amber100 = '#ffecb3'
  public static readonly amber200 = '#ffe082'
  public static readonly amber300 = '#ffd54f'
  public static readonly amber400 = '#ffca28'
  public static readonly amber500 = '#ffc107'
  public static readonly amber600 = '#ffb300'
  public static readonly amber700 = '#ffa000'
  public static readonly amber800 = '#ff8f00'
  public static readonly amber900 = '#ff6f00'
  public static readonly amberA100 = '#ffe57f'
  public static readonly amberA200 = '#ffd740'
  public static readonly amberA400 = '#ffc400'
  public static readonly amberA700 = '#ffab00'
  public static readonly orange50 = '#fff3e0'
  public static readonly orange100 = '#ffe0b2'
  public static readonly orange200 = '#ffcc80'
  public static readonly orange300 = '#ffb74d'
  public static readonly orange400 = '#ffa726'
  public static readonly orange500 = '#ff9800'
  public static readonly orange600 = '#fb8c00'
  public static readonly orange700 = '#f57c00'
  public static readonly orange800 = '#ef6c00'
  public static readonly orange900 = '#e65100'
  public static readonly orangeA100 = '#ffd180'
  public static readonly orangeA200 = '#ffab40'
  public static readonly orangeA400 = '#ff9100'
  public static readonly orangeA700 = '#ff6d00'
  public static readonly deepOrange50 = '#fbe9e7'
  public static readonly deepOrange100 = '#ffccbc'
  public static readonly deepOrange200 = '#ffab91'
  public static readonly deepOrange300 = '#ff8a65'
  public static readonly deepOrange400 = '#ff7043'
  public static readonly deepOrange500 = '#ff5722'
  public static readonly deepOrange600 = '#f4511e'
  public static readonly deepOrange700 = '#e64a19'
  public static readonly deepOrange800 = '#d84315'
  public static readonly deepOrange900 = '#bf360c'
  public static readonly deepOrangeA100 = '#ff9e80'
  public static readonly deepOrangeA200 = '#ff6e40'
  public static readonly deepOrangeA400 = '#ff3d00'
  public static readonly deepOrangeA700 = '#dd2c00'
  public static readonly brown50 = '#efebe9'
  public static readonly brown100 = '#d7ccc8'
  public static readonly brown200 = '#bcaaa4'
  public static readonly brown300 = '#a1887f'
  public static readonly brown400 = '#8d6e63'
  public static readonly brown500 = '#795548'
  public static readonly brown600 = '#6d4c41'
  public static readonly brown700 = '#5d4037'
  public static readonly brown800 = '#4e342e'
  public static readonly brown900 = '#3e2723'
  public static readonly blueGrey50 = '#eceff1'
  public static readonly blueGrey100 = '#cfd8dc'
  public static readonly blueGrey200 = '#b0bec5'
  public static readonly blueGrey300 = '#90a4ae'
  public static readonly blueGrey400 = '#78909c'
  public static readonly blueGrey500 = '#607d8b'
  public static readonly blueGrey600 = '#546e7a'
  public static readonly blueGrey700 = '#455a64'
  public static readonly blueGrey800 = '#37474f'
  public static readonly blueGrey900 = '#263238'
  public static readonly grey50 = '#fafafa'
  public static readonly grey100 = '#f5f5f5'
  public static readonly grey200 = '#eeeeee'
  public static readonly grey300 = '#e0e0e0'
  public static readonly grey400 = '#bdbdbd'
  public static readonly grey500 = '#9e9e9e'
  public static readonly grey600 = '#757575'
  public static readonly grey700 = '#616161'
  public static readonly grey800 = '#424242'
  public static readonly grey900 = '#212121'
  public static readonly black = '#000000'
  public static readonly white = '#ffffff'
}

export default ColorsGesco
