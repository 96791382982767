// ** Dropdowns Imports
import UserDropdown from './UserDropdown'

import {NavItem, NavLink} from 'reactstrap'

const NavbarUser = () => {
  return (
    <ul className="nav navbar-nav align-items-center ms-auto">
      {/*  <NavItem className="d-none d-lg-block">
        <NavLink className="nav-link-style"> // <ThemeToggler /></NavLink>
      </NavItem>
      <NavItem className="d-none d-lg-block">
        <NavLink className="nav-link-style">
         // <Power className="ficon" onClick={resetLoginDoLogoff} /> 
        </NavLink>
      </NavItem>*/}
      <UserDropdown />
    </ul>
  )
}
export default NavbarUser
