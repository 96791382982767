import Constant from '../utility/Constants'

class Agent {
  static getJsonData = async (url = '') => {
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      //mode: 'no-cors', // no-cors, *cors, same-origin
      //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': '*',
        'Content-Type': 'text/plain',
        Accept: '*/*'
      }
    })

    const ret = await response.text()

    console.log(ret)

    return ret
  }

  static postJsonData = async (url: string, data: any) => {
    console.log('postJsonData', {url, data})
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: data
    })

    const ret = await response.text()

    console.log(ret)

    return ret
  }

  static fetchWithTimeout = async (resource: any, options: any) => {
    const {timeout = 8000} = options

    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), timeout)
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    })
    clearTimeout(id)

    return response
  }

  static postBinaryData = async (url = '', data: any) => {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //mode: 'cors', // no-cors, *cors, same-origin
      //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/octet-stream',
        Accept: 'application/octet-stream'
      },
      //redirect: 'follow', // manual, *follow, error
      //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: data // body data type must match "Content-Type" header
    })

    return response.arrayBuffer()
  }
}

export default Agent
