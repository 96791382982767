//import './wdyr' // <--- first import

// ** React Imports
import {Suspense, lazy} from 'react'
import {createRoot} from 'react-dom/client'
import {HashRouter} from 'react-router-dom'

// ** Redux Imports
import {store} from './redux/store'
import {Provider} from 'react-redux'

// ** ThemeColors Context

import {ThemeContext} from './utility/context/ThemeColors'

// ** ThemeConfig
import themeConfig from './configs/themeConfig'

// ** Toast
import {Toaster} from 'react-hot-toast'

import {Provider as ProviderJotai} from 'jotai'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
// import 'prismjs'
// import 'prismjs/themes/prism-tomorrow.css'
// import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

import 'bootstrap/dist/css/bootstrap.min.css'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Sentry
import * as Sentry from '@sentry/react'
import isDev from './utility/DevDetect'

// ** Service Worker
//import * as serviceWorker from "./serviceWorker"

// ** Lazy load app
//const LazyApp = lazy(() => import('./App'))
import App from './App'

if (isDev()) {
  // dev code
} else {
  //production code
  Sentry.init({
    dsn: 'https://0ca99e3430e047c212b51517857939cd@o235146.ingest.sentry.io/4506541556039680',
    release: `varbox-ui@${process.env.REACT_APP_VERSION}`,
    //integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const container: any = document.getElementById('root')
const root = createRoot(container)

root.render(
  <HashRouter>
    <ProviderJotai>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <ThemeContext>
            <App />
            <Toaster
              position={themeConfig.layout.toastPosition}
              toastOptions={{className: 'react-hot-toast'}}
            />
          </ThemeContext>
        </Suspense>
      </Provider>
    </ProviderJotai>
  </HashRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
