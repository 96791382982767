import {useAtom} from 'jotai'
import {Suspense, useEffect} from 'react'
import {Helmet} from 'react-helmet'

// ** Router Import
import Router from './router/Router'
import agent from './utility/agent'
import {loggedUserAtom, isMqttAtom, mqttConnAtom} from './utility/atoms'

const App = () => {
  const [logUser] = useAtom(loggedUserAtom)
  const [mqttConnectionObj] = useAtom(mqttConnAtom)
  const [isMqtt] = useAtom(isMqttAtom)

  useEffect(() => {
    console.log('App useeffect')
    if (logUser != null) {
      console.log('TODO fix')
      // agent.setToken(logUser.token) //TODO: fix
    }
  }, [])

  return (
    <Suspense fallback={null}>
      <Router />
      {mqttConnectionObj != null && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{mqttConnectionObj?.alarmName}</title>
        </Helmet>
      )}
    </Suspense>
  )
}

export default App
